import {useQuery, gql} from "@apollo/client";

const LOGIN_USER_INFO = gql`
    query GetLoginUserInfo {
        user: getUser {
            id
            name
            loginId
            company {
                id
                name
                companyGroup {
                    id
                    name
                    logo {
                        id
                        name: originFileName
                        size
                        contentType
                    }
                    domain
                    basic
                }
            }
            roles {
                code
            }
        }
    }
`;

export function useUser() {
    return useQuery(LOGIN_USER_INFO, {
        fetchPolicy: 'no-cache'
    });
}
